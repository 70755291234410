<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium"
                         :label-width="this.env.label_width">

                    <el-form-item label="上下架状态">
                        <el-tag size="mini" v-if="info.publish_state===0" type="warning">下架</el-tag>
                        <el-tag size="mini" v-if="info.publish_state===1" type="success">上架</el-tag>
                    </el-form-item>
                    <el-form-item label="券包名称">
                        <span class="form-span-text">{{ info.coupon_bale_title }}</span>
                    </el-form-item>
                    <el-form-item label="券包封面">
                        <Qnupload v-if="info.cover" v-model="info.cover" :sum="1" :readonly="true"/>
                        <span v-else class="form-span-text">未设置</span>
                    </el-form-item>
                    <el-form-item label="券包价格">
                        <span class="form-span-text">￥{{ info.price }}</span>
                    </el-form-item>
                    <el-form-item label="优惠券价值">
                        <span class="form-span-text">￥{{ info.cost }}</span>
                    </el-form-item>
                    <el-form-item label="优惠券张数">
                        <span class="form-span-text">{{ info.coupon_count }}张</span>
                    </el-form-item>
                    <el-form-item label="优惠券">
                        <el-card class="box-card">
                            <el-tag class="form-tag_colour-tag"
                                    style="cursor:pointer"
                                    @click="toseecoupon(item.coupon_uuid)"
                                    :key="index"
                                    v-for="(item,index) in info.coupon_s"
                                    size="medium"
                                    effect="light">
                                {{ item.coupon_title }}【满{{ item.full_money }}减{{
                                    item.coupon_money
                                }}】有效期:
                                <span v-if="item.validity_time===0">无限制</span>
                                <span v-else>{{ item.validity_time }}天</span>
                            </el-tag>
                        </el-card>
                    </el-form-item>
                    <el-form-item label="券包规则">
                        <el-card class="box-card">
                            <span class="form-span-text">{{ info.bale_rule }}</span>
                        </el-card>
                    </el-form-item>
                    <el-form-item label="添加时间">
                        <span class="form-span-text">{{ info.create_time }}</span>
                    </el-form-item>

                    <el-form-item>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            coupon_bale_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let coupon_bale_uuid = this.$route.query.coupon_bale_uuid
            if (coupon_bale_uuid !== undefined) {
                this.coupon_bale_uuid = coupon_bale_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.isreturn()
                    }
                });
            }
        },
        // 券包详情
        getinfo() {
            let postdata = {
                api_name: "coupon.bale.getinfo",
                token: this.Tool.get_l_cache('token'),
                coupon_bale_uuid: this.coupon_bale_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看优惠券
        toseecoupon(coupon_uuid) {
            this.$router.push({path: '/coupon/library/info', query: {coupon_uuid}})
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
